<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import useProducts from "../../../hooks/useProducts";
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router/auto";
import ProductLayout from "../../../layouts/ProductLayout.vue";
import { setPageTitle } from "../../../utils/pageTitle";
import { useThemeStore } from "@/stores/themeStore";
import { connectToServerApi } from "@/utils/agGridUtils";

import Warehouses from "@/components/common/Warehouses.vue";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const { fetchProduct, product } = useProducts();

const route = useRoute();
const themeStore = useThemeStore();

const INBOUND_ORDERS_URL = `/products/${route.params.id}/inbound_order_items`;
const PRODUCT_EXTERNAL_FILTERS_KEY = "products-external-filter";

let gridApi;
const context = ref(null);

onBeforeMount(async () => {
  await fetchProduct(route.params.id);
  setPageTitle(`Product -${product.value.id}`);
});

const onGridReady = async (params) => {
  gridApi = params.api;
  context.value = {
        apiUrl:INBOUND_ORDERS_URL,
        filterKey: PRODUCT_EXTERNAL_FILTERS_KEY,
        advancedFilterKeys:[]
    };
  gridApi.setGridOption(
    "serverSideDatasource",
    connectToServerApi()
  );
};

const columnDefs = [
  { field: "id", headerName: "Inbound #", cellDataType: "text" },
  { field: "order.order_status.name", headerName: "Status", cellDataType: "text" },
  { field: "order.warehouse.nickname", headerName: "Warehouse", cellDataType: "text" },
  { field: "order.appt.id", headerName: "Appt", cellDataType: "text" },
  { field: "order.po_number", width: 180, headerName: "PO Number", cellDataType: "text" },
  { field: "qty", headerName: "Qty", cellDataType: "text" },
  { field: "units_label", headerName: "Units", cellDataType: "text" },
  { field: "qty_received", headerName: "Received", cellDataType: "text" },
  { field: "qty_put_away", headerName: "Put Away", cellDataType: "text" },
  { field: "order.created_at", headerName: "Created At", cellDataType: "text" },
  { field: "order.received_at", headerName: "Received At", cellDataType: "text" },
  { field: "order.put_at", headerName: "Put Away At", cellDataType: "text" },
];

const defaultColDef = {
  sortable: true,
  suppressHeaderMenuButton: true,
  width: 120,
};

const gridOptions = {
  rowModelType: "serverSide",
};

const warehouseFiltersChangedCallback = () => {
  gridApi.onFilterChanged();
};
</script>

<template>
  <div id="grid-wrapper">
    <Warehouses
      class="mb-6"
      :callBack="warehouseFiltersChangedCallback"
      :parent-filter="PRODUCT_EXTERNAL_FILTERS_KEY"
    />
    <ProductLayout :product="product">
      <div class="ml-4 mb-4">
        <h4 class="text-center">Inbound Activities</h4>
      </div>
      <div class="inbound-table d-flex">
        <AgGridVue
          style="width: 100%; height: 100%"
          :class="themeStore.agTheme"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :context="context"
        >
        </AgGridVue>
      </div>
    </ProductLayout>
  </div>
</template>

<style>
.inbound-table {
  height: 600px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
